import React, {useState, useRef, useEffect} from 'react'
import _ from 'lodash'
import {Spinner} from 'reactstrap'
import { Stage, Layer, Image, Transformer, Rect, Group} from "react-konva";
import {
    actionUpdateBgImage,
    actionUpdateBgImageRef, actionUpdateLoadingBackground
} from '../../context/SelectedVariantContext/action'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'

// here is another way to update the image
class LayerBgImage extends React.Component {
    state = {
        image: null
    }

    componentDidMount() {
        this.loadImage()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.background !== this.props.background) {
            this.loadImage()
        }
    }

    loadImage() {
        this.image = new window.Image()
        this.image.src = this.props.background
        this.image.crossOrigin = 'Anonymous'
        this.image.addEventListener('load', this.handleLoad)
    }

    handleLoad = () => {
        this.setState({
            image: this.image,
        }, () => {
            this.props.onLoaded();
        })
    }

    render() {
        const {wRect, hRect, xPosition, yPosition} = this.props
        return (
            <Image
                image={this.state.image}
                width={wRect || 600}
                height={hRect || 600}
                x={xPosition || 0}
                y={yPosition || 0}
                ref={this.props.bgImageRef}
            />
        );
    }
}

class LayerImage extends React.Component {
    state = {
        image: null
    }

    componentDidMount() {
        this.loadImage()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.background !== this.props.background) {
            this.loadImage()
        }
    }

    loadImage() {
        this.image = new window.Image()
        this.image.src = this.props.background
        this.image.crossOrigin = 'Anonymous'
        this.image.addEventListener('load', this.handleLoad)
    }

    handleLoad = () => {
        this.setState({
            image: this.image,
        }, () => {
            //this.props.onLoaded();
        })
    }

    render() {
        const {width, height, wPreview, hPreview} = this.props
        return (
            <Image
                image={this.state.image}
                width={width}
                height={height}
                x={(wPreview-600)/2}
                y={(hPreview-600)/2}
            />
        );
    }
}

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, onChangeState }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragMove={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onDragEnd={(e) => {
                    onChangeState({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={() => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChangeState({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    flipEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

const PreviewBackgroundImage = (props) => {
    const {
        state: {currentBgImage, currentBgImageType, product},
        dispatch
    } = useSelectedVariantContext()
    const {mockups} = product
    let mockupInfos = _.get(mockups[0], 'meta.mockup_infos', [])
    const stageRef = useRef();
    const bgImageRef = useRef();
    const groupRef = useRef();
    const {imageProp, reloadBgPreview, index, wPreview = 600, hPreview = 600} = props
    const [xPosition, setXPosition] = useState(currentBgImage?.x || (wPreview - 600) / 2)
    const [yPosition, setYPosition] = useState(currentBgImage?.y || (hPreview - 600) / 2)
    const [wRect, setWRect] = useState(wPreview)
    const [hRect, setHRect] = useState(hPreview)
    const [selectedId, selectShape] = React.useState(`rect${index}`);

    const onLoadedBackgroundImage = () => {
        if (currentBgImage?.file) {
            const base64 = bgImageRef.current.toDataURL({
                x: (wPreview - 600) / 2,
                y: (hPreview - 600) / 2,
                pixelRatio: 1.66666666666667,
                width: 600,
                height: 600
            })
            dispatch(actionUpdateBgImageRef(base64))
        }
        dispatch(actionUpdateLoadingBackground(false))
    }

    const onChangeBgState = (newAttrs) => {
        const {id, ...restAttrs} = newAttrs;
        setXPosition(restAttrs.x)
        setYPosition(restAttrs.y)
        setWRect(restAttrs.width)
        setHRect(restAttrs.height)
        dispatch(actionUpdateBgImage({
            ...currentBgImage,
            ...restAttrs
        }))
        setTimeout(() => {
            const base64 = bgImageRef.current.toDataURL({
                x: (wPreview - 600) / 2,
                y: (hPreview - 600) / 2,
                pixelRatio: 1.66666666666667,
                width: 600,
                height: 600
            })
            dispatch(actionUpdateBgImageRef(base64))
        }, 500)
    }

    const onChangeBg = (newAttrs) => {
        //const {id, ...restAttrs} = newAttrs;
        setXPosition(newAttrs.x)
        setYPosition(newAttrs.y)
        setWRect(newAttrs.width)
        setHRect(newAttrs.height)
        // dispatch(actionUpdateBgImage({
        //     ...currentBgImage,
        //     ...restAttrs
        // }))
    }

    useEffect(() => {
        setXPosition(currentBgImage.x || (wPreview - 600) / 2)
        setYPosition(currentBgImage.y || (hPreview - 600) / 2)
        setWRect(currentBgImage.width || 600)
        setHRect(currentBgImage.height || 600)
    }, [currentBgImage, wPreview, hPreview])

    return (
        <>
            <Stage
                className="bgImage"
                ref={stageRef}
                width={wPreview}
                height={hPreview}
                onMouseDown={e => {
                    // deselect when clicked on empty area
                    const clickedOnEmpty = e.target === e.target.getStage();
                    if (clickedOnEmpty) {
                        selectShape(null);
                    }
                }}
            >
                <Layer>
                    <Group
                        ref={groupRef}
                        clipFunc={(ctx) => {
                            ctx.save();
                            ctx.rect((wPreview - 600) / 2, (hPreview - 600) / 2, 600, 600);
                            ctx.restore();
                        }}
                        x={0}
                        y={0}
                        height={600}
                        width={600}
                    >
                        <LayerBgImage
                            bgImageRef={bgImageRef}
                            background={currentBgImage?.file || mockupInfos[0].parts[0].image_path}
                            currentBgImageType={currentBgImageType}
                            xPosition={xPosition}
                            yPosition={yPosition}
                            hRect={hRect}
                            wRect={wRect}
                            currentBgImage={currentBgImage}
                            onLoaded={onLoadedBackgroundImage}
                        />
                        <LayerImage
                            background={imageProp}
                            width={600}
                            height={600}
                            hPreview={hPreview}
                            wPreview={wPreview}
                        />
                    </Group>
                    {(currentBgImageType === 'original' && currentBgImage.file) && (
                        <React.Fragment>
                            <Rectangle
                                shapeProps={{
                                    x: xPosition,
                                    y: yPosition,
                                    width: wRect,
                                    height: hRect,
                                    // fill: 'green',
                                    id: `rect${index}`,
                                }}
                                isSelected={selectedId === `rect${index}`}
                                onSelect={() => {
                                    selectShape(`rect${index}`);
                                }}
                                onChangeState={onChangeBgState}
                                onChange={onChangeBg}
                            />
                        </React.Fragment>
                    )}
                </Layer>
            </Stage>
            {(reloadBgPreview) && (
                <div className='ImageFirstLoading loadingBackground text-center' style={{height: hPreview}}>
                    <Spinner style={{color: '#007bff'}} />
                </div>
            )}
        </>
    );
};

export default PreviewBackgroundImage
