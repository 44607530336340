import React, {useEffect, useRef, useState} from 'react'
import './style.scss'
import classNames from 'classnames'
import pluralize from '../../../../helpers/pluralize'
import {Input} from 'reactstrap'

const DropdownFilter = (props) => {
    const {attribute, name, onChange} = props
    const {type} = attribute
    const [values, setValues] = useState(attribute.values)
    const [selectedValues, setSelectedValues] = useState(attribute.values.map((v) => v.code))
    const [isOpen, setIsOpen] = useState(false)
    const buttonRef = useRef(null)
    const checkboxesRef = useRef(null)

    const _toggleFilter = () => setIsOpen(!isOpen)

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                isOpen &&
                checkboxesRef.current &&
                buttonRef.current &&
                !checkboxesRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [isOpen])

    const _handleFilterColor = (event) => {
        const {value} = event.target
        if (value) {
            const filterValues = values.filter((v) => v.text.toLowerCase().includes(value))
            setValues(filterValues)
        } else {
            setValues(attribute.values)
        }
    }

    return (
        <div className="DropdownFilterWrapper">
            <button
                ref={buttonRef}
                className={classNames(`btn btn-default ellipsis text-left`, {isOpen})}
                onClick={_toggleFilter}
            >
                {`${pluralize(selectedValues.length, name, 's')} selected`}
            </button>
            {isOpen && (
                <div className="checkboxes" ref={checkboxesRef}>
                    {attribute.type === 'color' && (
                        <div className="p-2">
                            <Input
                                className="form-control"
                                onChange={_handleFilterColor}
                                placeholder="Search color name..."
                            />
                        </div>
                    )}
                    <div className="inner-wrap">
                        <div className="d-flex custom-control custom-checkbox">
                            <Input
                                type="checkbox"
                                className="custom-control-input ckkBox val me-3"
                                checked={selectedValues.length === values.length}
                                onChange={() => {
                                    const isSelectAll = selectedValues.length === values.length
                                    onChange({
                                        name,
                                        value: isSelectAll ? [] : values.map((v) => v.code),
                                        type: 'all',
                                    })
                                    setSelectedValues(isSelectAll ? [] : attribute.values.map((v) => v.code))
                                }}
                                id={`all_${name}`}
                            />
                            <label className="custom-control-label" htmlFor={`all_${name}`}>
                                Select all
                            </label>
                        </div>
                        {!!values &&
                            values.map((v) => {
                                const {text, code} = v
                                return (
                                    <div key={code} className="d-flex custom-control custom-checkbox">
                                        <Input
                                            id={code}
                                            type="checkbox"
                                            className="custom-control-input ckkBox val me-3"
                                            onChange={() => {
                                                onChange({name, value: code, type: 'single'})
                                                const newSelectedValues = [...selectedValues]
                                                const idx = newSelectedValues.indexOf(code)
                                                if (idx > -1) {
                                                    newSelectedValues.splice(idx, 1)
                                                } else {
                                                    newSelectedValues.push(code)
                                                }
                                                setSelectedValues(newSelectedValues)
                                            }}
                                            checked={selectedValues.includes(code)}
                                        />
                                        <label
                                            className="custom-control-label d-flex align-items-center"
                                            htmlFor={code}
                                        >
                                            {type === 'color' && (
                                                <div
                                                    className="ColorItem me-1"
                                                    style={{background: code, border: '1px solid #dee2e6'}}
                                                />
                                            )}
                                            {text}
                                        </label>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DropdownFilter
