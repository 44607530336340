export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID
export const HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV

export const addHotjarScript = (id = HOTJAR_ID, version = HOTJAR_SV) => {
    const script = document.createElement('script')
    script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${id},hjsv:${version}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `
    return script;
}