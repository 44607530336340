import classNames from 'classnames'
import _ from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {ReactComponent as IconCheck} from '../../../../../assets/icons/check.svg'
import noImage from '../../../../../assets/images/no-image.png'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'

const PreviewImageItem = (props) => {
    const {image, isSelected, onselect} = props
    const {
        state: {currentBgImageBase64, product}
    } = useSelectedVariantContext()
    const {mockups} = product
    let mockupInfos = _.get(mockups[0], 'meta.mockup_infos', [])

    const [src, setSrc] = useState(null)
    const [loading, setLoading] = useState(true)

    const loadImage = useCallback(() => {
        const imageElement = new window.Image()
        imageElement.src = image
        imageElement.addEventListener('load', handleLoad)

        return () => {
            imageElement.removeEventListener('load', handleLoad)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    const handleLoad = () => {
        setLoading(false)
        setSrc(image)
    }

    useEffect(() => {
        loadImage()
    }, [loadImage])

    const onError = (e) => {
        e.target.src = noImage
    }

    return (
        <div className={classNames('PreviewImageItem', {active: isSelected})} onClick={onselect}>
            {loading ? (
                <div className="lightui1">
                    <div className="lightui1-shimmer">
                    </div>
                </div>
            ) : (
                <>
                    {(currentBgImageBase64 || mockupInfos[0].parts[0].image_path) && (
                        <img className="background" src={currentBgImageBase64 || mockupInfos[0].parts[0].image_path} onError={onError} alt='' />
                    )}
                    <img src={src} onError={onError} alt='' />
                </>
            )}

            {isSelected && (
                <div className="Mask">
                    <span className="Icon">
                        <IconCheck width={12} height={8} />
                    </span>
                </div>
            )}
        </div>
    )
}

export default PreviewImageItem
