import React, {useEffect} from 'react'
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import MockupGeneratorContainer from './components/step2/MockupGeneratorContainer'
import MockupGeneratorRedirect from './components/redirect'
import WelcomePageContainer from './components/welcome'
import {addHotjarScript} from './helpers/addHotjarScript'

function App() {
    useEffect(() => {
        const hotjar = addHotjarScript();
        document.head.appendChild(hotjar);

        return () => {
            document.head.removeChild(hotjar);
        }
    }, [])
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MockupGeneratorRedirect />} />
                    <Route path="/ping" element={<WelcomePageContainer />} />
                    <Route path="/:productId" element={<MockupGeneratorContainer />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App

