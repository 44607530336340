import React, {Fragment} from 'react'
import {Button} from 'reactstrap'
import ImageUpload from '../../../../../../assets/images/upload-artwork.png'
import {IMAGE_TYPES, MAX_IMAGE_UPLOAD_SIZE, SVG_TYPE} from '../../../../../../constant'
import PFBoxUploader from '../../../../../../shared/PFBoxUploader'
import {toaster} from '../../../../../../shared/PFToast'
import {useSelectedVariantContext} from '../../../../context/SelectedVariantContext'
import {
    actionUpdateBgImage, actionUpdateListBbImages, actionUpdateLoadingBackground
} from '../../../../context/SelectedVariantContext/action'
// import useUploadBackground from '../../../../../../hooks/useUploadBackground'
import {fileToBase64, getImageDimensions} from '../../../../../../helpers/imageFormat'
import randomstring from 'randomstring'
import {getInfomationBackground} from '../../../../../../helpers/createProductCombine'

const UploadByComputer = (props) => {
    const {toggle, accept, wPreview = 600, hPreview = 600} = props
    const {
        state: {currentBgImageType, listBackgroundImages},
        dispatch
    } = useSelectedVariantContext()
    // const {uploadFile} = useUploadFile()
    // const {uploadBackground} = useUploadBackground()

    const _validateFileUpload = (file) => {
        let isValid = true
        if (!IMAGE_TYPES.includes(file.type) && file.type !== SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'The image file is not supported',
            })
        }
        if (file.size > MAX_IMAGE_UPLOAD_SIZE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'The image file exceeded the maximum limit 50Mb',
            })
        }
        return isValid
    }

    const _handleChangeFile = async (files) => {
        const file = files[0]
        if (!_validateFileUpload(file)) return

        toggle()
        dispatch(actionUpdateLoadingBackground(true))

        const fileBase64 = await fileToBase64(file)
        const dimensions = await getImageDimensions(fileBase64)
        const key = randomstring.generate(7)
        const info = await getInfomationBackground(currentBgImageType, {
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
        }, wPreview, hPreview)
        const {width, height, x, y} = info
        const backgroundImages = {
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
            id: `backgroundImage`,
            file: fileBase64,
            key,
            width,
            height,
            x,
            y,
        }
        await dispatch(actionUpdateBgImage(backgroundImages))
        await dispatch(actionUpdateListBbImages([...listBackgroundImages, {
            file: fileBase64,
            key,
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
        }]))
        // dispatch(actionUpdateLoadingBackground(true))
        // dispatch(actionSetRunningImageCompress(true))
        // toggle()
        // await uploadBackground(file)
        // dispatch(actionUpdateLoadingBackground(false))
    }

    return (
        <Fragment>
            <PFBoxUploader
                id="inputUploadArtwork_computer"
                onChange={_handleChangeFile}
                multiple={false}
                isDisabled={false}
                accept={accept || 'image/png, image/jpeg, image/jpg'}
            >
                <img src={ImageUpload} alt="upload" />
                <div className="Text">Click or drag and drop file to upload</div>
            </PFBoxUploader>

            <div className="footerPopup d-flex justify-content-end">
                <Button color="default" onClick={toggle}>
                    Cancel
                </Button>
            </div>
        </Fragment>
    )
}

export default UploadByComputer

