import {
    ADD_IMAGE_LAYER,
    ADD_TEXT_LAYER,
    SAVE_CACHE_CAPTURE,
    CHANGE_ACTIVE_SIDE,
    CHANGE_BACKGROUND_COLOR,
    CHANGE_BACKGROUND_IMAGES,
    CHANGE_BACKGROUND_IMAGES_TYPE,
    CHANGE_BACKGROUND_IMAGES_REF,
    CHANGE_LOADING_BACKGROUND,
    UPDATE_LIST_BACKGROUND_IMAGES,
    CHANGE_LINKED_VARIANT,
    CHANGE_SELECTED_LAYER,
    CHANGE_STAGE_DRAG,
    CHANGE_STATUS_PUBLISH,
    CREATE_STAGE_REF,
    DUPLICATE_LAYER,
    INIT_APPLIED_VARIANTS,
    INIT_PRODUCT_DATA,
    MOVE_LAYERS,
    REDO_HISTORY,
    REMOVE_SELECTED_LAYER,
    RERENDER_STATE,
    SET_CAMPAIGN_DETAIL,
    UNDO_HISTORY,
    UPDATE_GALLERY_IMAGE,
    UPDATE_LAYER_ATTRIBUTE,
    UPDATE_PICKER_VARIANTS,
    UPDATE_PREVIEW_IMAGES,
    UPDATE_SCREEN_DISPLAY,
    UPDATE_SELECTED_ATTRIBUTE,
    UPDATE_SIZE_EDITOR_WRAPPER,
    UPDATE_VARIANT_RETAIL_PRICE,
    SET_RUNNING_IMAGE_COMPRESS,
    SET_IMAGE_COMPRESS_PROGRESS,
    SET_IS_ADMIN,
    COPY_LAYER,
    PASTE_LAYER,
    UPDATE_APPLY_ALL_PLACEMENTS,
    UPDATE_IMAGE_LAYER_LOADING,
    REDUX_DEVTOOL_JUMP,
} from './constants'
import {
    handleAddImageLayer,
    handleAddTextLayer,
    handleChangeActiveSide,
    handleChangeBgColor,
    handleChangeBgImage,
    handleChangeBgImageType,
    handleChangeBgImageRef,
    handleSetLoadingBackground,
    handleUpdateListBackgroundImages,
    handleChangeLinkedVariant,
    handleChangeSelectedAttribute,
    handleCreateStageRef,
    handleDuplicateLayer,
    handleInitAppliedVariants,
    handleMoveLayers,
    handleRedo,
    handleRemoveLayer,
    handleSaveCacheCapture,
    handleUndo,
    handleUpdateLayerAttribute,
    handleUpdatePickerVariants,
    handleUpdateProductDetail,
    handleChangeApplyAllPlacements
} from './helper'
import ReduxDevtool from '../../../../devtools/ReduxDevtool'

// import {getRatioDefault, hideToolbar} from './helper'

/**
 * @param {State} state
 * @param {*} action
 * @returns
 */
const reducer = (state, action) => {
    switch (action.type) {
        case INIT_PRODUCT_DATA:
            const {_id, title, attributes, description, slug} = action.payload
            return {
                ...state,
                product: action.payload,
                productDetail: {
                    ...state.productDetail,
                    _id,
                    title,
                    slug,
                    attributes,
                    description,
                },
            }
        case UNDO_HISTORY:
            return handleUndo(state)
        case REDO_HISTORY:
            return handleRedo(state)
        case CHANGE_STATUS_PUBLISH:
            return {
                ...state,
                isPublish: action.payload,
            }
        case UPDATE_SIZE_EDITOR_WRAPPER:
            return {
                ...state,
                editorWrapper: {...state.editorWrapper, ...action.payload},
            }
        case UPDATE_SCREEN_DISPLAY:
            if (action.payload === 'design') {
                return {
                    ...state,
                    display: action.payload,
                    selectedLayer: null,
                    runningImageCompress: false,
                    imageCompressProgress: 0,
                }
            }
            return {
                ...state,
                display: action.payload,
            }
        case INIT_APPLIED_VARIANTS:
            return handleInitAppliedVariants(state)
        case UPDATE_VARIANT_RETAIL_PRICE:
            return {
                ...state,
                pickerVariants: action.payload,
            }
        case CREATE_STAGE_REF:
            return handleCreateStageRef(state, action.payload)
        case CHANGE_ACTIVE_SIDE:
            return handleChangeActiveSide(state, action.payload)
        case CHANGE_STAGE_DRAG:
            const isDrag = action.payload
            if (isDrag === state.stageDrag) return state
            return {
                ...state,
                stageDrag: isDrag ?? !state.stageDrag,
                selectedLayer: null,
            }
        case ADD_TEXT_LAYER:
            return handleAddTextLayer(state)
        case CHANGE_SELECTED_LAYER:
            return {
                ...state,
                selectedLayer: action.payload,
            }
        case UPDATE_LAYER_ATTRIBUTE:
            const {layer, updateHistory} = action.payload
            return handleUpdateLayerAttribute(state, layer, updateHistory)
        case ADD_IMAGE_LAYER:
            return handleAddImageLayer(state, action.payload)
        case REMOVE_SELECTED_LAYER:
            return handleRemoveLayer(state, action.payload)
        case DUPLICATE_LAYER:
            return handleDuplicateLayer(state, action.payload)
        case MOVE_LAYERS:
            return handleMoveLayers(state, action.payload)
        case UPDATE_PICKER_VARIANTS:
            return handleUpdatePickerVariants(state, action.payload)
        case UPDATE_SELECTED_ATTRIBUTE:
            return handleChangeSelectedAttribute(state, action.payload)
        case CHANGE_LINKED_VARIANT:
            return handleChangeLinkedVariant(state, action.payload)
        case UPDATE_PREVIEW_IMAGES:
            return {
                ...state,
                previewImages: action.payload,
                // loadedPreviewImages: action.payload,
                reloadPreview: false,
            }
        case CHANGE_BACKGROUND_COLOR:
            return handleChangeBgColor(state, action.payload)
        case CHANGE_BACKGROUND_IMAGES:
            return handleChangeBgImage(state, action.payload)
        case CHANGE_BACKGROUND_IMAGES_TYPE:
            return handleChangeBgImageType(state, action.payload)
        case CHANGE_BACKGROUND_IMAGES_REF:
            return handleChangeBgImageRef(state, action.payload)
        case CHANGE_LOADING_BACKGROUND:
            console.log('reloadBgPreview', 3)
            return handleSetLoadingBackground(state, action.payload)
        case UPDATE_LIST_BACKGROUND_IMAGES:
            return handleUpdateListBackgroundImages(state, action.payload)
        case RERENDER_STATE:
            return {...state}
        case SAVE_CACHE_CAPTURE:
            return handleSaveCacheCapture(state, action.payload)
        case UPDATE_GALLERY_IMAGE:
            return {
                ...state,
                galleryImage: action.payload,
            }
        case SET_CAMPAIGN_DETAIL:
            return handleUpdateProductDetail(state, action.payload)
        case SET_RUNNING_IMAGE_COMPRESS:
            return {
                ...state,
                runningImageCompress: action.payload,
            }
        case SET_IMAGE_COMPRESS_PROGRESS:
            return {
                ...state,
                imageCompressProgress: action.payload,
            }
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload,
            }
        case COPY_LAYER:
            return {
                ...state,
                copyLayer: action.payload,
            }
        case PASTE_LAYER:
            if (!state.copyLayer) return state
            const payload = {
                currLayer: state.copyLayer,
                options: {scopeAll: false},
            }
            return handleDuplicateLayer(state, payload)
        case UPDATE_APPLY_ALL_PLACEMENTS:
            return handleChangeApplyAllPlacements(state, action.payload)
        case UPDATE_IMAGE_LAYER_LOADING:
            return {
                ...state,
                loadingImageLayer: action.payload,
            }
        case REDUX_DEVTOOL_JUMP:
            return {
                ...action.payload,
            }
        default:
            return state
    }
}

const UseReducerWithReduxDevtool = (state, action) => {
    return ReduxDevtool.useReducerWithReduxDevtool(state, action, reducer)
}


export default UseReducerWithReduxDevtool

