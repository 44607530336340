import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import UploadByAssets from './UploadByAssets'
import UploadByComputer from './UploadByComputer'
import { getUsageCapacity } from '../../../../../../services/ArtworkTemplateServices'
import { isBackofficeEnv } from '../../../../../../helpers/isBackoffceEnv'

const TabUploadType = (props) => {
    const [activeTab, setActiveTab] = useState('computer')

    const [capacity, setCapacity] = useState(null)
    
    const _fetchCapacity = async () => {
        try {
            const {success, data} = await getUsageCapacity()
            if (success) setCapacity(data)
        } catch(error) {}
    }

    useEffect(() => {
        isBackofficeEnv() && _fetchCapacity()
    }, [])

    return (
        <div className="Tabs">
            <ul>
                <li
                    className={classnames('tabUpload', {active: activeTab === 'assets'})}
                    onClick={() => setActiveTab('assets')}
                >
                    Your images
                </li>
                <li
                    className={classnames('tabUpload', {active: activeTab === 'computer'})}
                    onClick={() => setActiveTab('computer')}
                >
                    Upload from computer
                </li>
            </ul>

            <TabContent activeTab={activeTab} className="TabContent">
                <TabPane tabId="assets">
                    <UploadByAssets {...props} />
                </TabPane>
                <TabPane tabId="computer">
                    <UploadByComputer {...props} activeTab={activeTab} capacity={capacity} />
                </TabPane>
            </TabContent>
        </div>
    )
}

export default TabUploadType

