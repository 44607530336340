import {
    getLinksUploadBackground,
    uploadTempArtwork
} from '../services/ArtworkTemplateServices'
import {toaster} from '../shared/PFToast'

const useUploadBackgroundPublish = () => {
    const _fetchUploadLink = async () => {
        try {
            const {success, data} = await getLinksUploadBackground({limit: 1})
            if (!success) {
                return
            }
            return data
        } catch (error) {}
    }

    const uploadBackground = async (file) => {
        try {
            const data = await _fetchUploadLink()
            const {url, key} = data
            await uploadTempArtwork(url, file, '')

            return key
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message || 'An error occurred while reading the image',
            })
        } finally {

        }
    }

    return {uploadBackground}
}

export default useUploadBackgroundPublish

