import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Carousel, {Modal, ModalGateway} from 'react-images'
import {toaster} from './PFToast'
import { getVariantImages } from '../services/ProductServices'

class FeatureImage extends Component {
    state = {
        srcValid: '',
        openModal: false,
        variantImages: [],
        loadingImages: false,
    }

    _handleLoad = () => {
        this.setState({
            srcValid: true,
        })
    }

    _handError = () => {
        this.setState({
            srcValid: false,
        })
    }

    _getBackgroundImage = () => {
        const {srcValid} = this.state

        if ('' === srcValid) {
            return ''
        }

        if (true === srcValid) {
            return this.props.src
        }

        return '/assets/images/placeholder.png'
    }

    _getWrapperAttributes = () => {
        const {className, width, id} = this.props

        return {
            className: `FeatureImage text-center d-inline-block${className ? ` ${className}` : ''}`,
            style: {width},
            ...(id && {id}),
        }
    }

    _toggleVariantImages = async () => {
        try {
            const {variant = ''} = this.props
            if (!variant._id) return
            const {success, data, message} = await getVariantImages(variant._id)
            if (!success) {
                throw new Error(message)
            }
            const variantImages = data.map((item) => {
                return {
                    caption: '',
                    src: item.thumb,
                }
            })
            this.setState({openModal: true, variantImages})
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    _closeModal = () => {
        this.setState({openModal: false})
    }

    render() {
        const {openModal, variantImages} = this.state
        const {src, bgSize, alt, bgImage} = this.props
        const bg = this._getBackgroundImage()
        const wrapperAtt = this._getWrapperAttributes()

        return (
            <div {...wrapperAtt}>
                <div
                    className="ImageWrapper"
                    style={{backgroundImage: `url("${bg}")`, backgroundSize: bgSize, cursor: 'pointer'}}
                >
                    {bgImage && (
                        <img
                            src={bgImage}
                            alt={alt}
                            className="backgroundImage"
                        />
                    )}
                    <img
                        src={src}
                        alt={alt}
                        onLoad={this._handleLoad}
                        onError={this._handError}
                        onClick={this._toggleVariantImages}
                    />
                    <ModalGateway>
                        {openModal && variantImages.length > 0 && (
                            <Modal onClose={this._closeModal} closeOnBackdropClick={false} closeOnEsc={true}>
                                <Carousel
                                    isFullscreen={false}
                                    views={variantImages}
                                />
                            </Modal>
                        )}
                    </ModalGateway>
                </div>
            </div>
        )
    }
}

FeatureImage.defaultProps = {
    width: 50,
    bgSize: 'contain',
    bgImage: '',
    alt: '',
}

FeatureImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bgSize: PropTypes.string,
    bgImage: PropTypes.string,
    id: PropTypes.string,
}

export default FeatureImage
